import Footer from '@skyscanner-internal/global-components/footer';
import logger from 'saddlebag-logger';

export default (props) => {
  const newProps = { ...props };
  if (typeof window !== 'undefined') {
    newProps.logger = logger;
  }
  return <Footer {...newProps} />;
};
